import React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Drawer,
  useTheme,
  useMediaQuery,
  ListSubheader,
} from '@material-ui/core';
import RestaurantTimeIcon from '@material-ui/icons/Timer';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import DescriptionIcon from '@material-ui/icons/Description';
import AuditedIcon from '@material-ui/icons/MenuBook';
import { useHistory, useLocation } from 'react-router-dom';
import AppsIcon from '@material-ui/icons/Apps';
import Footer from './footer';
import { useUser } from '../../../context/user';
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    root: { zIndex: theme.zIndex.drawer },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
  })
);

interface DrawerLink {
  label: string;
  path: string;
  icon: JSX.Element;
  disabled?: boolean;
  hasPermission?: boolean;
}

interface Sidebar {
  isOpen: boolean;
  toggle: () => void;
}

const Sidebar = ({ isOpen, toggle }: Sidebar) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { permissions } = useUser();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const shouldRenderSidebar = !['/help', '/profile'].includes(location.pathname);

  const drawerItems: DrawerLink[] = [
    {
      label: t('shared.reports.salesConsolidated'),
      path: '/consolidated',
      icon: <AttachMoneyIcon />,
      hasPermission: permissions?.includes('CONSOLIDATED'),
    },
    {
      label: t('shared.reports.salesDetail'),
      path: '/sales-detail',
      icon: <DescriptionIcon />,
      hasPermission: permissions?.includes('SALES'),
    },
    {
      label: t('shared.reports.lifeCycle'),
      path: '/life-cycle',
      icon: <AvTimerIcon />,
      hasPermission: permissions?.includes('LIFECYCLE'),
    },
    {
      label: t('shared.reports.auditedSales'),
      path: '/audited-sales',
      icon: <AuditedIcon />,
      hasPermission: permissions?.includes('AUDIT'),
    },
  ];

  const handleLinkClick = (path: string) => {
    if (isMobile) {
      toggle();
    }
    history.push(path);
  };

  const drawerList = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {permissions?.includes('RESTAURANT_TIME') && (
          <ListItem
            button
            onClick={() => handleLinkClick('/restaurant-time')}
            selected={location.pathname === '/restaurant-time'}
          >
            <ListItemIcon>
              <RestaurantTimeIcon />
            </ListItemIcon>
            <ListItemText primary="Restaurant Time" />
          </ListItem>
        )}
        {permissions?.includes('DASHBOARD') && (
          <ListItem
            button
            onClick={() => handleLinkClick('/dashboard')}
            selected={location.pathname === '/dashboard'}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        )}
        <ListSubheader>{t('shared.labels.reports')}</ListSubheader>
        {drawerItems.map(
          item =>
            item.hasPermission && (
              <ListItem
                key={`drawer-item-${item.label}`}
                button
                onClick={() => handleLinkClick(item.path)}
                selected={location.pathname === item.path}
                disabled={item.disabled}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            )
        )}
      </List>
      <Divider />
      <List>
        <ListItem button onClick={() => (window.location.href = process.env.REACT_APP_LAUNCHER_URL || '')}>
          <ListItemIcon>
            <AppsIcon />
          </ListItemIcon>
          <ListItemText primary="Flex Tiles" />
        </ListItem>
      </List>
      <Footer />
    </div>
  );

  if (!shouldRenderSidebar) return null;

  return (
    <nav className={classes.drawer} aria-label="sidebar">
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={isOpen}
          onClose={toggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          style={{ zIndex: theme.zIndex.drawer }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawerList}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawerList}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default Sidebar;
