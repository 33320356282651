/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactNode, useState, createContext, useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CustomAlertAction } from '../types';
import { AxiosError } from 'axios';
import { AuthError } from '@azure/msal-browser';

interface Alert {
  title: string;
  description: string;
}

interface AlertContextInterface {
  alert: Alert | undefined;
  showAlert: (title: string, description: string, actions?: CustomAlertAction[]) => void;
  showError: (error: unknown) => void;
}

const initialState: AlertContextInterface = {
  alert: undefined,
  showAlert: () => {},
  showError: () => {},
};

interface ProviderProps {
  children: ReactNode;
}

export const AlertContext = createContext(initialState);

export const AlertProvider = ({ children }: ProviderProps) => {
  const [alert, setAlert] = useState<Alert | undefined>();
  const [customActions, setCustomActions] = useState<CustomAlertAction[]>([]);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const codedErrors = [
    'exceeded_number_of_records',
    'invalid_parameters',
    'popup_window_error',
    'invalid_token',
    'Network_Error',
  ];

  const showAlert = (title: string, message: string, actions?: CustomAlertAction[]) => {
    const description = codedErrors.includes(message) ? t(`errors.coded.${message}`) : message;
    actions && setCustomActions(actions);
    setAlert({ title, description });
    setOpen(true);
  };

  /**
   * Ajustar los parámetros para mostrar un error de respuesta de endpoint.
   * Api Gateway manda en el body un objeto tipo { message: string }
   * Los mensajes de error de nuestras lambdas mandan un string de forma nombre_error
   * @param error de tipo AxiosError.
   */
  const setApiResponseError = (error: AxiosError) => {
    const requestId = error.response?.headers['requestid'];
    setAlert({
      title: t('errors.unexpected.title'),
      description: requestId
        ? t('errors.unexpected.message') + t('errors.unexpected.withRequestId', { requestId })
        : t('errors.unexpected.message'),
    });
    if (error.response && error.response.data.message) {
      const codeError = error.response.data;
      let title = t('errors.awsError.title');
      let description = t('errors.awsError.description', {
        detail: t(`errors.coded.${error.response?.status}`),
      });
      switch (codeError['message']) {
        case 'no_app_permission':
        case 'no_api_permission':
          title = t(`errors.coded.${codeError['message']}.title`);
          description = t(`errors.coded.${codeError['message']}.description`, {
            user: `"${codeError['username']}"`,
          });
          break;
        default:
          break;
      }
      setAlert({ title, description });
    } else {
      setAlert({
        title: t(`errors.coded.${error.response?.data}.title`),
        description: t(`errors.coded.${error.response?.data}.description`, {
          requestId: error.response?.headers['requestid'],
        }),
      });
    }
  };

  const isAxiosResponseError = (error: unknown) => {
    return (error as AxiosError).response?.data !== undefined;
  };

  const isAxiosRequestError = (error: unknown) => {
    return Boolean(!(error as AxiosError).response && (error as AxiosError).request);
  };

  const isAuthError = (error: unknown) => {
    return Boolean((error as AuthError).errorMessage);
  };

  const showError = (error: unknown) => {
    setAlert({ title: t('errors.unexpected.title'), description: t('errors.unexpected.message') });
    if (isAxiosResponseError(error)) {
      setApiResponseError((error as unknown) as AxiosError);
    }
    if (isAxiosRequestError(error)) {
      setAlert({ title: t('errors.request.title'), description: t('errors.request.description') });
    }
    if (isAuthError(error)) {
      setAlert({
        title: t('errors.auth.title'),
        description: t('errors.auth.description', {
          error: ((error as unknown) as AuthError).errorMessage,
        }),
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AlertContext.Provider value={{ alert, showAlert, showError }}>{children}</AlertContext.Provider>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{alert?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{alert?.description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {customActions.length > 0 ? (
            customActions.map(action => (
              <Button
                key={`action-button-${action.label}`}
                onClick={() => action.handleClick()}
                color="primary"
              >
                {action.label}
              </Button>
            ))
          ) : (
            <Button onClick={handleClose} color="secondary">
              Ok
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export const useAlert = () => useContext(AlertContext);
