/* eslint-disable @typescript-eslint/camelcase */
import { format } from 'date-fns';
import { Column, GroupCriteria, IndicatorName, LabelLanguage, Store } from '../types';
import en from '../static/locales/en.json';
import es from '../static/locales/es.json';
import fr from '../static/locales/fr.json';
import pt from '../static/locales/pt.json';

export const compareStores = (a: Store, b: Store): number => {
  if (a.storeAcronym < b.storeAcronym) return -1;
  if (a.storeAcronym > b.storeAcronym) return 1;
  return 0;
};

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export const compareGroupingOptions = <T>(a: Column<T>, b: Column<T>): number => {
  const options: { [key: string]: number } = {
    country: 0,
    store: 1,
    area: 2,
    channel: 3,
    paymentMethod: 4,
    posError: 5,
    division: 6,
    regionalManagement: 7,
    region: 8,
    management: 9,
    supervision: 10,
  };
  return options[a.field] - options[b.field];
};

export const formatDecimal = (x: number | undefined, locale: string) => {
  if (x === undefined) return undefined;
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const exp = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
  ];
  let i;
  for (i = exp.length - 1; i > 0; i--) {
    if (Math.abs(x) >= exp[i].value) {
      break;
    }
  }
  if (i === 0) {
    return (
      (x / exp[i].value).toLocaleString(locale, { maximumFractionDigits: 0 }).replace(rx, '$1') +
      exp[i].symbol
    );
  } else {
    return (
      (x / exp[i].value).toLocaleString(locale, { maximumFractionDigits: 2 }).replace(rx, '$1') +
      exp[i].symbol
    );
  }
};

export const formatTime = (time: string) => {
  const timeUnitSymbols = ['h', 'm', 's'];
  const timeUnits = time.split(':');
  if (timeUnits[0] === '00' && timeUnits[1] === '00' && timeUnits[2] === '00') return '00s';
  return time
    .split(':')
    .map((timeUnit, index) => {
      if (parseInt(timeUnit) > 0) {
        return `${timeUnit.toString()}${timeUnitSymbols[index]}`;
      } else return '';
    })
    .join('');
};

export const formatPercentage = (value: number) =>
  `${value.toLocaleString('es', { maximumFractionDigits: 2 })}%`;

export const dateToUtc = (date: Date): Date => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

const formatNumber = (value: number) => value.toLocaleString('es', { maximumFractionDigits: 0 });

export const formatSeconds = (numberOfSeconds: number) =>
  new Date(1000 * numberOfSeconds).toISOString().substr(11, 8);

export const formatter: { [key: string]: (value: string | number) => string } = {
  route_time: (value: unknown) => formatTime(value as string),
  total_time: (value: unknown) => formatTime(value as string),
  restaurant_time: (value: unknown) => formatTime(value as string),
  delivery_time: (value: unknown) => formatTime(value as string),
  total_orders: (value: unknown) => formatNumber(value as number),
  total_sales: (value: unknown) => formatNumber(value as number),
  active_restaurants: (value: unknown) => formatNumber(value as number),
  canceled_orders: (value: unknown) => formatNumber(value as number),
  average_ticket: (value: unknown) => formatNumber(value as number),
  pos_error: (value: unknown) => formatPercentage(value as number),
  pos_order_error_code: (value: unknown) => formatNumber(value as number),
  restaurant_time_hour: (value: unknown) => formatSeconds(value as number),
  partners_sales_percentage: (value: unknown) => formatPercentage(value as number),
  partners_orders_percentage: (value: unknown) => formatPercentage(value as number),
};

export const compareNumberGroup = (
  a: { name: string; value: string },
  b: { name: string; value: string }
) => {
  if (a.value < b.value) return 1;
  if (a.value > b.value) return -1;
  return 0;
};

export const getPerformance = (
  indicator: IndicatorName,
  target: string | number,
  value: string | number
): 'positive' | 'negative' | undefined => {
  switch (indicator) {
    case 'canceled_orders':
      return (value as number) <= (target as number) ? 'positive' : 'negative';
    case 'pos_error':
      return (value as number) <= (target as number) ? 'positive' : 'negative';
    case 'restaurant_time':
      return (value as string) <= (target as string) ? 'positive' : 'negative';
    case 'route_time':
      return (value as string) <= (target as string) ? 'positive' : 'negative';
    case 'delivery_time':
      return (value as string) <= (target as string) ? 'positive' : 'negative';
    case 'total_time':
      return (value as string) <= (target as string) ? 'positive' : 'negative';
    default:
      return undefined;
  }
};

export const formatDate = (date: string, locale: string): string => {
  return format(
    date.includes('T') ? new Date(date) : new Date(`${date}T00:00:00Z`),
    locale.includes('en') ? 'MM/dd/yyyy HH:mm' : 'dd/MM/yyyy HH:mm'
  );
};

export const getTranslatedLabels = (language: string): LabelLanguage => {
  switch (language) {
    case 'en':
      return { ...en.shared.labels };
    case 'es':
      return { ...es.shared.labels };
    case 'fr':
      return { ...fr.shared.labels };
    case 'pt':
      return { ...pt.shared.labels };
    default:
      return { ...en.shared.labels };
  }
};

export const formatNumberOfSeconds = (numberOfSeconds: number): string => {
  numberOfSeconds = numberOfSeconds === undefined ? 0 : numberOfSeconds;
  const hours = Math.floor(numberOfSeconds / 3600);
  const minutes = Math.floor((numberOfSeconds - hours * 3600) / 60);
  const seconds = numberOfSeconds - hours * 3600 - minutes * 60;
  return `${minutes < 10 ? 0 : ''}${minutes}:${seconds < 10 ? 0 : ''}${seconds}`;
};

export const formatInteger = (number: number, digits: number) =>
  number.toLocaleString('es', { minimumIntegerDigits: digits });

export const indicatorFormatter: { [key: string]: (value: GroupCriteria) => IndicatorName } = {
  route_time: (value: string) => {
    switch (value) {
      case 'area':
        return 'route_time_area';
      case 'platform_id':
        return 'route_time_platform';
      default:
        return 'route_time';
    }
  },
  total_time: (value: string) => {
    switch (value) {
      case 'area':
        return 'total_time_area';
      case 'platform_id':
        return 'total_time_platform';
      default:
        return 'total_time';
    }
  },
  restaurant_time: (value: string) => {
    switch (value) {
      case 'area':
        return 'restaurant_time_area';
      case 'platform_id':
        return 'restaurant_time_platform';
      case 'logistic_id':
        return 'restaurant_time_logistic';
      default:
        return 'restaurant_time';
    }
  },
  delivery_time: (value: string) => {
    switch (value) {
      case 'area':
        return 'delivery_time_area';
      case 'platform_id':
        return 'delivery_time_platform';
      default:
        return 'delivery_time';
    }
  },
  pos_error: (value: string) => {
    switch (value) {
      case 'pos_order_error_code':
        return 'pos_error_error_code';
      default:
        return 'pos_error';
    }
  },
  restaurant_time_hour: (value: string) => {
    switch (value) {
      case 'area':
        return 'restaurant_time_area_hour';
      case 'platform_id':
        return 'restaurant_time_platform_hour';
      case 'logistic_id':
        return 'restaurant_time_logistic_hour';
      default:
        return 'restaurant_time_hour';
    }
  },
  partners_orders_percentage: (value: string) => {
    switch (value) {
      case 'platform_id':
        return 'partners_orders_percentage_platform';
      default:
        return 'partners_orders_percentage';
    }
  },
  partners_sales_percentage: (value: string) => {
    switch (value) {
      case 'platform_id':
        return 'partners_sales_percentage_platform';
      default:
        return 'partners_sales_percentage';
    }
  },
};
