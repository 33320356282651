import React, { useMemo, Suspense } from 'react';
import { render } from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { useMediaQuery, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { AlertProvider } from './context/alert';
import { AuthenticationProvider } from './context/authentication';
import { UserProvider } from './context/user';
import { I18nextProvider } from 'react-i18next';
import { usePreferences, PreferencesProvider } from './context/preferences';
import i18n from './i18n';
import Router from './router';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/error-boundary';
import LoadingPage from './components/loading';
import { MasterDataProvider } from './context/master-data';
import { ApiProvider } from './context/api';
import { FiltersProvider } from './context/filters';

const WrappedApp = () => {
  const { preferences } = usePreferences();
  const systemTheme = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light';
  let prefersDarkMode: 'light' | 'dark' = 'light';
  switch (preferences.theme) {
    case 'dark':
      prefersDarkMode = 'dark';
      break;
    case 'light':
      prefersDarkMode = 'light';
      break;
    case 'system':
      prefersDarkMode = systemTheme;
  }
  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          primary: {
            main: '#FFC72C',
          },
          secondary: {
            main: '#DA291C',
          },
          type: prefersDarkMode,
        },
        overrides: {
          MuiListSubheader: {
            sticky: {
              top: '-10px',
              zIndex: 1,
              position: 'sticky',
              backgroundColor: 'inherit',
            },
          },
        },
      }),
    [prefersDarkMode]
  );

  return (
    <MuiThemeProvider theme={theme}>
      <AlertProvider>
        <AuthenticationProvider>
          <ApiProvider>
            <UserProvider>
              <FiltersProvider>
                <MasterDataProvider>
                  <Router />
                </MasterDataProvider>
              </FiltersProvider>
            </UserProvider>
          </ApiProvider>
        </AuthenticationProvider>
      </AlertProvider>
    </MuiThemeProvider>
  );
};

const App = () => (
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Suspense fallback={<LoadingPage />}>
          <PreferencesProvider>
            <WrappedApp />
          </PreferencesProvider>
        </Suspense>
      </ErrorBoundary>
    </I18nextProvider>
  </React.StrictMode>
);

const root = document.getElementById('root');
if (root !== null) {
  // axiosConfig();
  render(<App />, root);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
