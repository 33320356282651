import React, { FunctionComponent } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Login from './components/login';
import { useAuthentication } from './context/authentication';
import { useUser } from './context/user';
import AppShell from './components/app-shell';
import LoadingPage from './components/loading';

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Login} />
      <PrivateRoute
        path={[
          '/dashboard',
          '/restaurant-time',
          '/consolidated',
          '/sales-detail',
          '/life-cycle',
          '/audited-sales',
          '/profile',
          '/help',
        ]}
        component={AppShell}
      />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>
);

interface PrivateRouteProps {
  component: FunctionComponent;
  path: string | string[];
}

export const PrivateRoute = ({ component, path }: PrivateRouteProps) => {
  const { isAuthenticated, loading } = useAuthentication();
  const { loading: userLoading } = useUser();

  if (loading || userLoading) return <LoadingPage />;

  return isAuthenticated ? <Route path={path} component={component} /> : <Redirect to="/" />;
};

export default Router;
