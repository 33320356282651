/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { DeliveryFilters, FilterLabels, QueryStringParameters, TimePeriod } from '../types';
import React from 'react';
import { format, isEqual, isSameDay, startOfDay, endOfDay, subDays } from 'date-fns';
import { startOfToday } from 'date-fns/esm';
import { useTranslation } from 'react-i18next';

interface FiltersContext {
  filters: DeliveryFilters;
  numberOfAppliedFilters: number;
  timePeriod: TimePeriod;
  updateFilters: (newFilters: Partial<DeliveryFilters>) => void;
  updateTimePeriod: (newTimePeriod: TimePeriod) => void;
  getQueryStringParameters: (
    fullDay?: boolean,
    optionalFilters?: DeliveryFilters,
    optionalTimePeriod?: TimePeriod,
    inDashboard?: boolean
  ) => QueryStringParameters;
  getFilterLabels: (getGroupBy?: boolean) => FilterLabels;
}

const initialState: FiltersContext = {
  numberOfAppliedFilters: 0,
  filters: {
    groupByFields: ['country', 'store', 'area', 'channel', 'orderType', 'status', 'paymentMethod'],
    division: '',
    countries: [],
    regionalManagements: [],
    regions: [],
    managements: [],
    supervisions: [],
    stores: [],
    partners: [],
    areas: [],
    status: [],
    errorCodes: [],
    fromDate: startOfToday(),
    toDate: new Date(),
    orderType: [],
    fromDateDashboard: startOfToday(),
    toDateDashboard: new Date(),
  },
  timePeriod: 'custom',
  updateFilters: () => {},
  updateTimePeriod: () => {},
  getQueryStringParameters: () => ({
    division: '',
    countries: '',
    regionalManagements: '',
    regions: '',
    managements: '',
    supervisions: '',
    stores: '',
    partners: '',
    fromDate: '',
    toDate: '',
    orderType: '',
    fromDateDashboard: '',
    toDateDashboard: '',
  }),
  getFilterLabels: () => ({}),
};

const FiltersContext = createContext(initialState);

export const FiltersProvider = ({ children }: { children: ReactNode }) => {
  const { t, i18n } = useTranslation();
  const [filters, setFilters] = useState<DeliveryFilters>(initialState.filters);
  const [numberOfAppliedFilters, setNumberOfAppliedFilters] = useState(0);
  const [timePeriod, setTimePeriod] = useState<TimePeriod>(
    isEqual(filters.fromDate, startOfDay(new Date())) && isSameDay(filters.toDate, new Date())
      ? 'today'
      : 'custom'
  );

  useEffect(() => {
    let filtersApplied = 0;
    filters.division !== '' && filtersApplied++;
    filters.countries.length > 0 && filtersApplied++;
    filters.regionalManagements.length > 0 && filtersApplied++;
    filters.regions.length > 0 && filtersApplied++;
    filters.managements.length > 0 && filtersApplied++;
    filters.supervisions.length > 0 && filtersApplied++;
    filters.stores.length > 0 && filtersApplied++;
    filters.partners.length > 0 && filtersApplied++;
    filters.areas.length > 0 && filtersApplied++;
    filters.errorCodes.length > 0 && filtersApplied++;
    setNumberOfAppliedFilters(filtersApplied);
  }, [filters]);

  const updateFilters = (updatedFilters: Partial<DeliveryFilters>) => {
    setFilters(f => ({ ...f, ...updatedFilters }));
  };

  const getFormattedFromDate = (date: Date, fullDay?: boolean) => {
    if (fullDay) {
      return startOfDay(date);
    } else {
      return date;
    }
  };

  const getFormattedToDate = (date: Date, fullDay?: boolean) => {
    if (fullDay) {
      return endOfDay(date);
    } else {
      return date;
    }
  };

  const getFromDate = (timePeriod: TimePeriod) => {
    switch (timePeriod) {
      case '30d':
        return startOfDay(subDays(new Date(), 30));
      case '7d':
        return startOfDay(subDays(new Date(), 7));
      default:
        return startOfToday();
    }
  };

  const updateTimePeriod = (newTimePeriod: TimePeriod) => {
    setTimePeriod(newTimePeriod);
  };

  const getQueryStringParameters = (
    fullDay?: boolean,
    optionalFilters?: DeliveryFilters,
    optionalTimePeriod?: TimePeriod,
    inDashboard?: boolean
  ): QueryStringParameters => {
    const currentFilters = optionalFilters ? { ...optionalFilters } : { ...filters };
    const currentTimePeriod = optionalTimePeriod ? optionalTimePeriod : timePeriod;
    const joinedCountries = currentFilters.countries.join();
    const joinedRegionalManagements = currentFilters.regionalManagements.map(rm => rm.id).join();
    const joinedRegions = currentFilters.regions.map(r => r.id).join();
    const joinedManagements = currentFilters.managements.map(m => m.id).join();
    const joinedSupervisions = currentFilters.supervisions.map(s => s.id).join();
    const joinedStores = currentFilters.stores.map(s => s.storeId).join();
    const joinedPartners = currentFilters.partners.map(p => p.id).join();
    const joinedAreas = currentFilters.areas.join();
    const joinedStatus = currentFilters.status.join();
    const joinedGroupBy = currentFilters.groupByFields.join();
    const joinedErrorCodes = currentFilters.errorCodes.join();
    const joinedOrderType = currentFilters.orderType.join();

    const fromDate =
      currentTimePeriod === 'custom'
        ? getFormattedFromDate(
            inDashboard ? currentFilters.fromDateDashboard : currentFilters.fromDate,
            fullDay
          )
        : getFromDate(currentTimePeriod);
    const toDate =
      currentTimePeriod === 'custom'
        ? getFormattedToDate(inDashboard ? currentFilters.toDateDashboard : currentFilters.toDate, fullDay)
        : endOfDay(new Date());

    return {
      division: currentFilters.division,
      fromDate: format(fromDate, 'yyyy-MM-dd HH:mm:ss'),
      toDate: format(toDate, 'yyyy-MM-dd HH:mm:ss'),
      country: joinedCountries !== '' ? joinedCountries : undefined,
      regional: joinedRegionalManagements !== '' ? joinedRegionalManagements : undefined,
      region: joinedRegions !== '' ? joinedRegions : undefined,
      mgmt: joinedManagements !== '' ? joinedManagements : undefined,
      supervision: joinedSupervisions !== '' ? joinedSupervisions : undefined,
      store: joinedStores !== '' ? joinedStores : undefined,
      partners: joinedPartners !== '' ? joinedPartners : undefined,
      areas: joinedAreas !== '' ? joinedAreas : undefined,
      status: joinedStatus !== '' ? joinedStatus : undefined,
      errorCodes: joinedErrorCodes !== '' ? joinedErrorCodes : undefined,
      groupBy: joinedGroupBy !== '' ? joinedGroupBy : undefined,
      orderType: joinedOrderType !== '' ? joinedOrderType : undefined,
    };
  };

  const getFilterLabels = (getGroupBy?: boolean): FilterLabels => {
    return {
      groupBy: getGroupBy ? filters.groupByFields.map(g => t(`shared.labels.${g}`)) : undefined,
      regional:
        filters.regionalManagements.length > 0 ? filters.regionalManagements.map(rm => rm.label) : undefined,
      region: filters.regions.length > 0 ? filters.regions.map(r => r.label) : undefined,
      mgmt: filters.managements.length > 0 ? filters.managements.map(m => m.label) : undefined,
      supervision: filters.supervisions.length > 0 ? filters.supervisions.map(s => s.label) : undefined,
      store: filters.stores.length > 0 ? filters.stores.map(s => s.storeAcronym) : undefined,
      partners: filters.partners.map(p => t(`shared.platform_id.${p.id}`)),
      language: i18n.language,
    };
  };

  return (
    <FiltersContext.Provider
      value={{
        filters,
        numberOfAppliedFilters,
        timePeriod,
        updateFilters,
        updateTimePeriod,
        getQueryStringParameters,
        getFilterLabels,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = () => useContext(FiltersContext);
