import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '../../../router';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { format, Locale } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';
import ptLocale from 'date-fns/locale/pt';
import { useTranslation } from 'react-i18next';

class LatinLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date: Date) {
    return format(date, 'dd/MM/yyyy HH:mm', { locale: this.locale });
  }
}

const localeUtilsMap: { [key: string]: typeof DateFnsUtils } = {
  en: DateFnsUtils,
  es: LatinLocalizedUtils,
  fr: LatinLocalizedUtils,
  pt: LatinLocalizedUtils,
};

const localeMap: { [key: string]: Locale } = {
  es: esLocale,
  pt: ptLocale,
  fr: frLocale,
  en: enLocale,
};

const Dashboard = lazy(() => import('../../dashboard'));
const Prototype = lazy(() => import('../../restaurant-time'));
const Consolidated = lazy(() => import('../../../reports/consolidated'));
const SalesDetail = lazy(() => import('../../../reports/sales-detail'));
const LifeCycle = lazy(() => import('../../../reports/life-cycle'));
const AuditedSales = lazy(() => import('../../../reports/audited-sales'));
const Profile = lazy(() => import('../../profile'));
const Help = lazy(() => import('../../help/index'));

const ContentRouter = () => {
  const { i18n } = useTranslation();
  return (
    <MuiPickersUtilsProvider utils={localeUtilsMap[i18n.language]} locale={localeMap[i18n.language]}>
      <Switch>
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/restaurant-time" component={Prototype} />
        <PrivateRoute path="/consolidated" component={Consolidated} />
        <PrivateRoute path="/sales-detail" component={SalesDetail} />
        <PrivateRoute path="/life-cycle" component={LifeCycle} />
        <PrivateRoute path="/audited-sales" component={AuditedSales} />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/help" component={Help} />
      </Switch>
    </MuiPickersUtilsProvider>
  );
};

export default ContentRouter;
