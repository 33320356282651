import { AppPermission, GroupCriteria } from '../types';
import rappi from '../static/assets/rappi.png';
import uberEats from '../static/assets/ubereats.png';
import mcdonalds from '../static/assets/mcdonalds.png';
import pedidosya from '../static/assets/pedidosya.png';
import glovo from '../static/assets/glovo.png';
import ifood from '../static/assets/ifood.png';
import didi from '../static/assets/didi.png';
import wabi from '../static/assets/wabi.png';
import gigigo from '../static/assets/gigigo.png';
import botmaker from '../static/assets/botmaker.png';
import marketMix from '../static/assets/market_mix.png';
import flexFleet from '../static/assets/flex_fleet.png';

export const IndicatorTypes = [
  'total_orders',
  'total_sales',
  'active_restaurants',
  'route_time',
  'restaurant_time',
  'delivery_time',
  'total_time',
  'canceled_orders',
  'average_ticket',
  'pos_error_percent',
] as const;

export const GroupableFields = [
  'country',
  'store',
  'date',
  'area',
  'channel',
  'orderLogisticsId',
  'orderType',
  'status',
  'paymentMethod',
  'posError',
  'regionalManagement',
  'region',
  'management',
  'supervision',
] as const;

export const GroupableFieldsManager = [
  'country',
  'store',
  'date',
  'area',
  'channel',
  'orderLogisticsId',
  'orderType',
  'status',
  'paymentMethod',
  'posError',
] as const;

export const DeliveryStatus = [
  'ACCEPTED',
  'CANCELLED',
  'DELIVERED',
  'DELIVERING',
  'PREPARING',
  'STAGING',
  'READY',
  'RECEIVED',
] as const;

export const DeliveryAreas = [
  'BKF',
  'DLV',
  'MOP',
  'CDP',
  'AUT',
  'MCC',
  'KSH',
  'O24',
  'CHK',
  'EALM',
  'CURB',
  'DELI',
  'KSH-CHK',
] as const;

export const DeliveryErrorCodes = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  60,
  61,
  99,
  130,
  131,
  132,
  133,
  134,
  135,
  502,
  1000,
  1001,
  1002,
  1003,
  1004,
];

export const ALL_SCREENS: AppPermission[] = [
  'RESTAURANT_TIME',
  'DASHBOARD',
  'CONSOLIDATED',
  'SALES',
  'LIFECYCLE',
  'AUDIT',
];

export const CodeRouteMap: { [key: string]: string } = {
  RESTAURANT_TIME: '/restaurant-time',
  DASHBOARD: '/dashboard',
  CONSOLIDATED: '/consolidated',
  SALES: '/sales-detail',
  LIFECYCLE: '/life-cycle',
  AUDIT: '/audited-sales',
};

export const RouteCodeMap: { [key: string]: AppPermission } = {
  '/restaurant-time': 'RESTAURANT_TIME',
  '/dashboard': 'DASHBOARD',
  '/consolidated': 'CONSOLIDATED',
  '/sales-detail': 'SALES',
  '/life-cycle': 'LIFECYCLE',
  '/audited-sales': 'AUDIT',
};

export const GroupLogo: { [key: string]: string } = {
  RAPPI: rappi,
  'UBER EATS': uberEats,
  GLOVO: glovo,
  'PEDIDOS YA': pedidosya,
  VTEX: mcdonalds,
  IFOOD: ifood,
  DIDI: didi,
  SALESFORCE: mcdonalds,
  WABI: wabi,
  GIGIGO: gigigo,
  BOTMAKER: botmaker,
  'MARKET MIX': marketMix,
  'FLEX FLEET': flexFleet,
};

export const OrdersTypes = ['PICKUP', 'DELIVERY', 'DELIVERY_BY_RESTAURANT', 'DELIVERY_BY_3PL'];

export const CriteriaOptions: { [key: string]: GroupCriteria[] } = {
  route_time: ['all', 'area', 'platform_id'],
  route_time_area: ['all', 'area', 'platform_id'],
  route_time_platform: ['all', 'area', 'platform_id'],
  restaurant_time: ['all', 'area', 'platform_id', 'logistic_id'],
  restaurant_time_hour: ['all', 'area', 'platform_id', 'logistic_id'],
  delivery_time: ['all', 'area', 'platform_id'],
  total_time: ['all', 'area', 'platform_id'],
  pos_error: ['all', 'pos_order_error_code'],
};

export const RTHCategories: string[] = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];

export const CriteriaFormatter: { [key: string]: string } = {
  all: 'TOTAL',
  area: 'area',
  platform_id: 'platform_id',
  logistic_id: 'order_logistics_id',
};
