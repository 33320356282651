/* eslint-disable @typescript-eslint/no-empty-function */
import Axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import React, { createContext, ReactNode, useContext } from 'react';
import {
  ChartIndicator,
  DeliveryPartnerResponse,
  IndicatorName,
  QueryStringParameters,
  AppPermission,
  Store,
  IndicatorResponse,
  GroupedChartIndicator,
} from '../types';
import {
  ConsolidatedSalesItem,
  LifeCycleItem,
  AuditedSalesItem,
  SalesDetailItem,
  LabelLanguage,
  FilterLabels,
} from '../types/Reports';
import { useAuthentication } from './authentication';

interface ApiContext {
  fetchPermissions: () => AxiosPromise<AppPermission[]>;
  fetchPartners: () => AxiosPromise<DeliveryPartnerResponse[]>;
  fetchOperativeStructure: () => AxiosPromise<Store[]>;
  fetchIndicator: (
    indicator: IndicatorName,
    queryParameters: QueryStringParameters
  ) => AxiosPromise<IndicatorResponse>;
  fetchIndicatorSeries: (
    indicator: IndicatorName,
    queryParameters: QueryStringParameters
  ) => AxiosPromise<ChartIndicator>;
  fetchGroupedIndicatorSeries: (
    indicator: IndicatorName,
    queryParameters: QueryStringParameters
  ) => AxiosPromise<GroupedChartIndicator>;
  fetchConsolidatedSales: (
    queryParameters: QueryStringParameters,
    exportReques?: boolean,
    labels?: LabelLanguage,
    filterLabels?: FilterLabels
  ) => AxiosPromise<ConsolidatedSalesItem[]>;
  fetchSalesDetail: (
    queryParameters: QueryStringParameters,
    exportReques?: boolean,
    labels?: LabelLanguage,
    filterLabels?: FilterLabels
  ) => AxiosPromise<SalesDetailItem[]>;
  fetchLifeCycle: (
    queryParameters: QueryStringParameters,
    exportReques?: boolean,
    labels?: LabelLanguage,
    filterLabels?: FilterLabels
  ) => AxiosPromise<LifeCycleItem[]>;
  fetchAuditedSales: (
    queryParameters: QueryStringParameters,
    exportReques?: boolean,
    labels?: LabelLanguage,
    filterLabels?: FilterLabels
  ) => AxiosPromise<AuditedSalesItem[]>;
}

const initialState: ApiContext = {
  fetchIndicator: () => new Promise(() => {}),
  fetchIndicatorSeries: () => new Promise(() => {}),
  fetchGroupedIndicatorSeries: () => new Promise(() => {}),
  fetchOperativeStructure: () => new Promise(() => {}),
  fetchPartners: () => new Promise(() => {}),
  fetchPermissions: () => new Promise(() => {}),
  fetchConsolidatedSales: () => new Promise(() => {}),
  fetchSalesDetail: () => new Promise(() => {}),
  fetchLifeCycle: () => new Promise(() => {}),
  fetchAuditedSales: () => new Promise(() => {}),
};

const ApiContext = createContext(initialState);

export const ApiProvider = ({ children }: { children: ReactNode }) => {
  const { getToken } = useAuthentication();
  const foeApi = Axios.create({
    baseURL:
      (process.env.REACT_APP_API_URL && `${process.env.REACT_APP_API_URL}/foe`) ||
      'https://api-flex-dev.arcosdorados.net/foe',
  });

  const rsApi = Axios.create({
    baseURL:
      (process.env.REACT_APP_API_URL && `${process.env.REACT_APP_API_URL}/rs`) ||
      'https://api-flex-dev.arcosdorados.net/rs',
  });

  rsApi.interceptors.request.use(async (value: AxiosRequestConfig) => {
    const { accessToken, idToken } = await getToken();
    if (idToken && accessToken) {
      value.headers = {
        'access-token': accessToken,
        Authorization: `Bearer ${idToken}`,
        appId: 'foe',
      };
      return value;
    } else throw Error('token-error');
  });

  foeApi.interceptors.request.use(async (value: AxiosRequestConfig) => {
    const { accessToken, idToken } = await getToken();
    if (idToken && accessToken) {
      value.headers = {
        'access-token': accessToken,
        Authorization: `Bearer ${idToken}`,
        appId: 'foe',
      };
      return value;
    } else throw Error('token-error');
  });

  const fetchPermissions = () => rsApi.get('/permissions');

  const fetchOperativeStructure = () => rsApi.get('/op-structures');

  const fetchPartners = () => foeApi.get('/partners');

  const fetchIndicator = (indicator: IndicatorName, filters: QueryStringParameters) => {
    const {
      fromDate,
      toDate,
      division,
      country,
      region,
      regional,
      mgmt,
      supervision,
      store,
      partners,
      areas,
      orderType,
    } = filters;
    return foeApi.get('/foe', {
      params: {
        indicator,
        division,
        country,
        regional,
        region,
        mgmt,
        supervision,
        store,
        fromDate,
        toDate,
        partners,
        areas,
        orderType,
      },
    });
  };

  const fetchIndicatorSeries = (indicator: IndicatorName, filters: QueryStringParameters) => {
    const {
      fromDate,
      toDate,
      division,
      country,
      region,
      regional,
      mgmt,
      supervision,
      store,
      partners,
      areas,
      orderType,
    } = filters;
    return foeApi.get('/indicator-series', {
      params: {
        indicator,
        division,
        country,
        regional,
        region,
        mgmt,
        supervision,
        store,
        fromDate,
        toDate,
        partners,
        areas,
        orderType,
      },
    });
  };

  const fetchGroupedIndicatorSeries = (indicator: IndicatorName, filters: QueryStringParameters) => {
    const {
      fromDate,
      toDate,
      division,
      country,
      region,
      regional,
      mgmt,
      supervision,
      store,
      partners,
      areas,
      orderType,
    } = filters;
    return foeApi.get('/indicator-series', {
      params: {
        indicator,
        division,
        country,
        regional,
        region,
        mgmt,
        supervision,
        store,
        fromDate,
        toDate,
        partners,
        areas,
        orderType,
      },
    });
  };

  const fetchConsolidatedSales = (
    queryParameters: QueryStringParameters,
    exportRequest?: boolean,
    labels?: LabelLanguage,
    filterLabels?: FilterLabels
  ) => {
    const {
      fromDate,
      toDate,
      division,
      country,
      region,
      regional,
      mgmt,
      supervision,
      store,
      partners,
      groupBy,
      errorCodes,
      areas,
      status,
      orderType,
    } = queryParameters;
    return foeApi.get('/consolidated-sales', {
      params: {
        fromDate,
        toDate,
        division,
        country,
        regional,
        region,
        mgmt,
        supervision,
        store,
        partners,
        groupBy,
        status,
        errorCodes,
        areas,
        export: exportRequest,
        labels,
        filterLabels,
        orderType,
      },
    });
  };

  const fetchSalesDetail = (
    queryParameters: QueryStringParameters,
    exportRequest?: boolean,
    labels?: LabelLanguage,
    filterLabels?: FilterLabels
  ) => {
    const {
      fromDate,
      toDate,
      division,
      country,
      region,
      regional,
      mgmt,
      supervision,
      store,
      partners,
      areas,
      errorCodes,
      status,
      orderType,
    } = queryParameters;
    return foeApi.get('/sales-detail', {
      params: {
        fromDate,
        toDate,
        division,
        country,
        regional,
        region,
        mgmt,
        supervision,
        store,
        errorCodes,
        partners,
        status,
        areas,
        export: exportRequest,
        labels,
        filterLabels,
        orderType,
      },
    });
  };

  const fetchLifeCycle = (
    queryParameters: QueryStringParameters,
    exportRequest?: boolean,
    labels?: LabelLanguage,
    filterLabels?: FilterLabels
  ) => {
    const {
      fromDate,
      toDate,
      division,
      country,
      region,
      regional,
      mgmt,
      supervision,
      store,
      partners,
      errorCodes,
      areas,
      status,
      orderType,
    } = queryParameters;
    return foeApi.get('/life-cycle', {
      params: {
        fromDate,
        toDate,
        division,
        country,
        regional,
        region,
        mgmt,
        supervision,
        store,
        partners,
        status,
        errorCodes,
        areas,
        export: exportRequest,
        labels,
        filterLabels,
        orderType,
      },
    });
  };

  const fetchAuditedSales = (
    queryParameters: QueryStringParameters,
    exportRequest?: boolean,
    labels?: LabelLanguage,
    filterLabels?: FilterLabels
  ) => {
    const {
      fromDate,
      toDate,
      division,
      country,
      region,
      regional,
      mgmt,
      supervision,
      store,
      partners,
      errorCodes,
      areas,
      status,
      orderType,
    } = queryParameters;
    return foeApi.get('/audited-sales', {
      params: {
        fromDate,
        toDate,
        division,
        country,
        regional,
        region,
        mgmt,
        supervision,
        store,
        partners,
        status,
        errorCodes,
        areas,
        export: exportRequest,
        labels,
        filterLabels,
        orderType,
      },
    });
  };

  return (
    <ApiContext.Provider
      value={{
        fetchIndicator,
        fetchIndicatorSeries,
        fetchGroupedIndicatorSeries,
        fetchOperativeStructure,
        fetchPartners,
        fetchPermissions,
        fetchConsolidatedSales,
        fetchSalesDetail,
        fetchLifeCycle,
        fetchAuditedSales,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => useContext(ApiContext);
