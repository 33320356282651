import React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  useMediaQuery,
  Tooltip,
  Hidden,
  Avatar,
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import HeaderMenu from './menu';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory, useLocation } from 'react-router-dom';
import HelpIcon from '@material-ui/icons/Help';
import { useTranslation } from 'react-i18next';
import McDonalsLogo from '../../../static/assets/mcdonals-logo-white.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: 36,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    navBarButton: {
      marginRight: theme.spacing(2),
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      paddingLeft: theme.spacing(1),
    },
    toolbarButton: {
      display: 'flex',
    },
    logo: {
      marginRight: theme.spacing(1),
    },
  })
);

interface Header {
  title: string;
  toggleDrawer: () => void;
}

const Header = ({ title, toggleDrawer }: Header) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const userCanGoBack = Boolean(['/profile', '/help'].find(path => location.pathname.includes(path)));

  const BackButton = () => (
    <IconButton
      color="inherit"
      aria-label="back-button"
      edge="start"
      onClick={() => history.goBack()}
      className={classes.navBarButton}
    >
      <ArrowBackIcon />
    </IconButton>
  );

  const HamburgerButton = () => (
    <Hidden smUp implementation="css">
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer}
        edge="start"
        className={classes.navBarButton}
      >
        <MenuIcon />
      </IconButton>
    </Hidden>
  );

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.appBar}>
          {userCanGoBack ? <BackButton /> : <HamburgerButton />}
          <Avatar alt="M" src={McDonalsLogo} className={classes.logo} />
          <Typography variant="h6" noWrap>
            {title}
          </Typography>
          <div className={classes.grow} />
          {!isMobile && (
            <div className={classes.toolbarButton}>
              <Tooltip title={t('navigation.tooltips.help').toString()}>
                <IconButton
                  aria-label="help"
                  aria-controls="header-help"
                  aria-haspopup="false"
                  onClick={() => history.push('/help')}
                  color="inherit"
                >
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
          <div className={classes.toolbarButton}>
            <IconButton
              aria-label="show more"
              aria-controls="header-menu"
              aria-haspopup="true"
              onClick={handleOpenMenu}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <HeaderMenu anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} />
    </>
  );
};

export default Header;
