/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, ReactNode, useState, useContext, useEffect } from 'react';
import {
  Store,
  MasterData,
  RegionalManagement,
  Region,
  Management,
  Supervision,
  Country,
  DeliveryPartner,
} from '../types';
import { useAlert } from './alert';
import { useAuthentication } from './authentication';
import { compareStores } from '../utils/functions';
import { useApi } from './api';
import { useFilters } from './filters';

interface MasterDataContext {
  filteredDivisions: string[];
  filteredCountries: string[];
  filteredRegionalManagements: RegionalManagement[];
  filteredRegions: Region[];
  filteredManagements: Management[];
  filteredSupervisions: Supervision[];
  filteredStores: Store[];
  filteredPartners: DeliveryPartner[];
  loading: boolean;
}

const initialState: MasterDataContext = {
  filteredDivisions: [],
  filteredCountries: [],
  filteredRegionalManagements: [],
  filteredRegions: [],
  filteredManagements: [],
  filteredSupervisions: [],
  filteredStores: [],
  filteredPartners: [],
  loading: false,
};

const MasterDataContext = createContext(initialState);

interface MasterDataProvider {
  children: ReactNode;
}

export const MasterDataProvider = ({ children }: MasterDataProvider) => {
  const { showError } = useAlert();
  const { fetchOperativeStructure, fetchPartners } = useApi();
  const { isAuthenticated } = useAuthentication();
  const { filters, updateFilters } = useFilters();
  const { division, countries, regionalManagements, regions, managements, supervisions } = filters;
  const [loading, setLoading] = useState(false);
  const [masterData, setMasterData] = useState<MasterData | undefined>(undefined);
  const [filteredDivisions, setFilteredDivisions] = useState<string[]>([]);
  const [filteredCountries, setFilteredCountries] = useState<string[]>([]);
  const [filteredRegionalManagements, setFilteredRegionalManagements] = useState<RegionalManagement[]>([]);
  const [filteredRegions, setFilteredRegions] = useState<Region[]>([]);
  const [filteredManagements, setFilteredManagements] = useState<Management[]>([]);
  const [filteredSupervisions, setFilteredSupervisions] = useState<Supervision[]>([]);
  const [filteredStores, setFilteredStores] = useState<Store[]>([]);
  const [filteredPartners, setFilteredPartners] = useState<DeliveryPartner[]>([]);

  useEffect(() => {
    const getRegionalManagements = (masterDataResponse: Store[]): RegionalManagement[] => {
      const regionalManagements: RegionalManagement[] = [];
      masterDataResponse.forEach(store => {
        if (
          regionalManagements.findIndex(item => item.id === store.regionalMgmtId) === -1 &&
          store.regionalMgmtId !== null
        ) {
          regionalManagements.push({
            label: store.regionalMgmt,
            id: store.regionalMgmtId,
            divisionId: store.divisionId,
            country: store.country,
          });
        }
      });
      return regionalManagements;
    };

    const getRegions = (masterDataResponse: Store[]): Region[] => {
      const regions: Region[] = [];
      masterDataResponse.forEach(store => {
        if (regions.findIndex(r => r.id === store.regionId) === -1 && store.regionId !== null) {
          regions.push({
            label: store.region,
            id: store.regionId,
            divisionId: store.divisionId,
            country: store.country,
            regionalMgmtId: store.regionalMgmtId,
          });
        }
      });
      return regions;
    };

    const getManagements = (masterDataResponse: Store[]): Management[] => {
      const managements: Management[] = [];
      masterDataResponse.forEach(store => {
        if (managements.findIndex(m => m.id === store.mgmtId) === -1 && store.mgmtId !== null) {
          managements.push({
            label: store.mgmt,
            id: store.mgmtId,
            divisionId: store.divisionId,
            country: store.country,
            regionalMgmtId: store.regionalMgmtId,
            regionId: store.regionId,
          });
        }
      });
      return managements;
    };

    const getSupervisions = (masterDataResponse: Store[]): Supervision[] => {
      const supervisions: Supervision[] = [];
      masterDataResponse.forEach(store => {
        if (
          supervisions.findIndex(s => s.id === store.supervisionId) === -1 &&
          store.supervisionId !== null
        ) {
          supervisions.push({
            label: store.supervision,
            id: store.supervisionId,
            divisionId: store.divisionId,
            country: store.country,
            regionalMgmtId: store.regionalMgmtId,
            regionId: store.regionId,
            mgmtId: store.mgmtId,
          });
        }
      });
      return supervisions;
    };

    const getCountries = (masterDataResponse: Store[]): Country[] => {
      const countries: Country[] = [];
      masterDataResponse.forEach(store => {
        if (countries.findIndex(country => country.label === store.country) === -1) {
          countries.push({
            division: store.division,
            divisionId: store.divisionId,
            id: store.countryId,
            label: store.country,
          });
        }
      });
      return countries;
    };

    const getDeliveryPartners = (
      deliveryPartnerResponse: {
        id: string;
        country: string;
        name: string;
      }[]
    ): DeliveryPartner[] => {
      const partners: DeliveryPartner[] = [];
      deliveryPartnerResponse.forEach(responsePartner => {
        const index = partners.findIndex(p => p.id === responsePartner.id);
        if (index === -1) {
          partners.push({
            id: responsePartner.id,
            name: responsePartner.name,
            countries: [responsePartner.country],
          });
        } else {
          if (!partners[index].countries.includes(responsePartner.country))
            partners[index].countries.push(responsePartner.country);
        }
      });
      return partners;
    };

    const getDivisions = (masterDataResponse: Store[]) => {
      const divisions: Set<string> = new Set();
      masterDataResponse.forEach(s => divisions.add(s.divisionId));
      return [...divisions];
    };

    const init = async () => {
      try {
        setLoading(true);
        const masterDataRegister = sessionStorage.getItem('master-data');
        if (masterDataRegister !== null) {
          const savedMasterData: MasterData = JSON.parse(masterDataRegister);
          setMasterData(savedMasterData);
          setFilteredDivisions(savedMasterData.divisions);
        } else {
          if (isAuthenticated) {
            const [masterDataResponse, partnersResponse] = await Promise.all([
              fetchOperativeStructure(),
              fetchPartners(),
            ]);
            const divisions = getDivisions(masterDataResponse.data);
            setFilteredDivisions(divisions);
            const countries = getCountries(masterDataResponse.data);
            const regionalManagements = getRegionalManagements(masterDataResponse.data);
            const regions: Region[] = getRegions(masterDataResponse.data);
            const managements: Management[] = getManagements(masterDataResponse.data);
            const supervisions: Supervision[] = getSupervisions(masterDataResponse.data);
            const filteredStores = masterDataResponse.data.sort(compareStores);
            const partners = getDeliveryPartners(partnersResponse.data);
            setMasterData({
              divisions,
              countries,
              regionalManagements,
              regions,
              managements,
              supervisions,
              filteredStores,
              partners,
            });
            sessionStorage.setItem(
              'master-data',
              JSON.stringify({
                divisions,
                countries,
                regionalManagements,
                regions,
                managements,
                supervisions,
                filteredStores,
                partners,
              })
            );
          }
        }
      } catch (error) {
        showError(error);
      } finally {
        setLoading(false);
      }
    };

    init();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    filterByDivision();
    // eslint-disable-next-line
  }, [division, masterData]);

  useEffect(
    () => {
      if (countries.length > 0) filterByCountry();
      else filterByDivision();
    },
    // eslint-disable-next-line
    [countries, masterData]
  );

  useEffect(() => {
    if (regionalManagements.length > 0) {
      filterByRegionalManagement();
    } else if (countries.length > 0) {
      filterByCountry();
    } else filterByDivision();
    // eslint-disable-next-line
  }, [masterData, regionalManagements]);

  useEffect(() => {
    if (regions.length > 0) {
      filterByRegion();
    } else if (regionalManagements.length > 0) {
      filterByRegionalManagement();
    } else if (countries.length > 0) {
      filterByCountry();
    } else filterByDivision();
    // eslint-disable-next-line
  }, [masterData, regions]);

  useEffect(() => {
    if (managements.length > 0) {
      filterByManagement();
    } else if (regions.length > 0) {
      filterByRegion();
    } else if (regionalManagements.length > 0) {
      filterByRegionalManagement();
    } else if (countries.length > 0) {
      filterByCountry();
    } else filterByDivision();
    // eslint-disable-next-line
  }, [masterData, managements]);

  useEffect(() => {
    if (supervisions.length > 0) {
      filterBySupervision();
    } else if (managements.length > 0) {
      filterByManagement();
    } else if (regions.length > 0) {
      filterByRegion();
    } else if (regionalManagements.length > 0) {
      filterByRegionalManagement();
    } else if (countries.length > 0) {
      filterByCountry();
    } else filterByDivision();
    // eslint-disable-next-line
  }, [masterData, supervisions]);

  function formattedStores(stores: Store[]): Store[] {
    let result: Store[] = [];
    let mapping: { [key: string]: Store[] } = {};
    stores.forEach(s => {
      if (!mapping[s.country]) mapping[s.country] = [];
      mapping[s.country].push(s);
    });
    Object.keys(mapping).forEach(key => {
      result = result.concat(mapping[key]);
    });
    return result;
  }

  const filterByDivision = () => {
    if (masterData) {
      setFilteredCountries(
        masterData.countries.filter(c => division.includes(c.divisionId)).map(c => c.label)
      );
      setFilteredRegionalManagements(
        masterData.regionalManagements.filter(r => division.includes(r.divisionId))
      );
      setFilteredRegions(masterData.regions.filter(r => division.includes(r.divisionId)));
      setFilteredManagements(masterData.managements.filter(m => division.includes(m.divisionId)));
      setFilteredSupervisions(masterData.supervisions.filter(s => division.includes(s.divisionId)));
      setFilteredStores(
        formattedStores(masterData.filteredStores.filter(store => division.includes(store.divisionId)))
      );
    }
  };

  const filterByCountry = () => {
    if (masterData) {
      setFilteredRegionalManagements(
        masterData.regionalManagements.filter(r => countries.includes(r.country))
      );
      setFilteredRegions(masterData.regions.filter(r => countries.includes(r.country)));
      setFilteredManagements(masterData.managements.filter(m => countries.includes(m.country)));
      setFilteredSupervisions(masterData.supervisions.filter(s => countries.includes(s.country)));
      setFilteredPartners(masterData.partners.filter(p => p.countries.some(c => countries.includes(c))));
      setFilteredStores(
        formattedStores(masterData.filteredStores.filter(store => countries.includes(store.country)))
      );
    }
  };

  const filterByRegionalManagement = () => {
    if (masterData) {
      setFilteredSupervisions(
        masterData.supervisions.filter(s => regionalManagements.map(m => m.id).includes(s.regionalMgmtId))
      );
      setFilteredRegions(
        masterData.regions.filter(r => regionalManagements.map(rm => rm.id).includes(r.regionalMgmtId))
      );
      setFilteredManagements(
        masterData.managements.filter(m => regionalManagements.map(rm => rm.id).includes(m.regionalMgmtId))
      );
      setFilteredStores(
        formattedStores(
          masterData.filteredStores.filter(s =>
            regionalManagements.map(rm => rm.id).includes(s.regionalMgmtId)
          )
        )
      );
    }
  };

  const filterByRegion = () => {
    if (masterData) {
      setFilteredRegionalManagements(
        masterData.regionalManagements.filter(rm => regions.map(r => r.regionalMgmtId).includes(rm.id))
      );
      setFilteredSupervisions(
        masterData.supervisions.filter(s => regions.map(r => r.id).includes(s.regionId))
      );
      setFilteredManagements(masterData.managements.filter(m => regions.map(r => r.id).includes(m.regionId)));
      setFilteredStores(
        formattedStores(masterData.filteredStores.filter(s => regions.map(r => r.id).includes(s.regionId)))
      );
    }
  };

  const filterByManagement = () => {
    if (masterData) {
      setFilteredRegionalManagements(
        masterData.regionalManagements.filter(rm => managements.map(m => m.regionalMgmtId).includes(rm.id))
      );
      setFilteredRegions(masterData.regions.filter(r => managements.map(m => m.regionId).includes(r.id)));
      setFilteredSupervisions(
        masterData.supervisions.filter(s => managements.map(m => m.id).includes(s.mgmtId))
      );
      setFilteredStores(
        formattedStores(masterData.filteredStores.filter(s => managements.map(m => m.id).includes(s.mgmtId)))
      );
    }
  };

  const filterBySupervision = () => {
    if (masterData) {
      setFilteredRegionalManagements(
        masterData.regionalManagements.filter(rm => supervisions.map(s => s.regionalMgmtId).includes(rm.id))
      );
      setFilteredRegions(masterData.regions.filter(r => supervisions.map(s => s.regionId).includes(r.id)));
      setFilteredManagements(
        masterData.managements.filter(m => supervisions.map(s => s.mgmtId).includes(m.id))
      );
      setFilteredStores(
        formattedStores(
          masterData.filteredStores.filter(s => supervisions.map(sup => sup.id).includes(s.supervisionId))
        )
      );
    }
  };

  /** Autocomplete Filters */

  useEffect(() => {
    if (filteredDivisions.length === 1) {
      updateFilters({ ...filters, division: filteredDivisions[0] });
    }
    // eslint-disable-next-line
  }, [filteredDivisions]);

  useEffect(() => {
    if (filters.division !== '' && filteredCountries.length === 1) {
      updateFilters({ ...filters, countries: [...filteredCountries] });
    }
    // eslint-disable-next-line
  }, [filteredCountries]);

  useEffect(() => {
    if (filters.division !== '' && filters.countries.length > 0 && filteredStores.length === 1) {
      updateFilters({ ...filters, stores: [...filteredStores] });
    }
    // eslint-disable-next-line
  }, [filteredStores]);

  return (
    <MasterDataContext.Provider
      value={{
        filteredDivisions,
        filteredCountries,
        filteredRegionalManagements,
        filteredRegions,
        filteredManagements,
        filteredSupervisions,
        filteredStores,
        filteredPartners,
        loading,
      }}
    >
      {children}
    </MasterDataContext.Provider>
  );
};

export const useMasterData = () => useContext(MasterDataContext);
